.applications {

  main {

    #applicationsList {
      position: relative;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      transition: all 0.2s;
      transform: scale(0.98);
      will-change: transform;
      user-select: none;
      cursor: pointer;

      &.active {
        cursor: grabbing;
        cursor: -webkit-grabbing;
        transform: scale(1);
      }

      .application {
        margin: 15px 0;

        .application-img {
          width: 100%;
          height: 156.25px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        h4 {
          margin: 8px 0 0;
          background: $red;
          padding: 30px 15px 15px;
          @include border-radius(0 0 0 15px);
          @include transition(0.5s all ease-in-out);
          position: relative;

          &:before {
            content: url('../../images/title-element.svg');
            position: absolute;
            display: block;
            top: -1px;
            left: 50%;
            @include transform(translateX(-50%));
            width: 50px;
            height: auto;
          }
        }

        &:hover {

          h4 {
            background: $darkGrey;
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .applications {

    main {

      #applicationsList {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);

        .application {
          @include flex-basis(47.5%);
        }
      }
    }
  }

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .applications {

    main {

      #applicationsList {
        margin: -15px 0 -35px;
        @include flex-direction(column);
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0 !important;
        }

        .application {
          margin: 15px;
          @include flex-basis(25%);
          width: 25%;
          min-width: 250px;
          max-width: 250px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (min-height: 768px) {
  .applications {

    main {

      #applicationsList {
        height: 540px;

        .application {

          .application-img {
            height: 156.25px;
          }
        }
      }
    }
  }
}

/* Shallow devices  */
@media (min-width: 768px) and (max-height: 767px) {

  .applications {

    main {

      #applicationsList {
        height: 450px;

        .application {

          .application-img {
            height: 114.25px;
          }
        }
      }
    }
  }

}
