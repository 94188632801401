// Hubspot form
#requestInfoForm {

  form {
    @extend .row;

    .field {
      @extend .col;
      @extend .col-md-4;

      &.hs-comments, &.hs-fieldtype-booleancheckbox {
        @extend .col-md-12;
      }

      &.hs-fieldtype-booleancheckbox {
        background: $white;
        color: $red;
        padding: 10px 15px;
        @include border-radius(0 15px 0 15px);
        margin-bottom: 1rem;

        label {
          margin: 0;
        }
      }

      &.hs_selected_product {
        display: none;
      }
    }

    .legal-consent-container, .hs-submit {
      @extend .col-md-12;
    }

    label {
      display: block;

      &.hs-error-msg {
        color: $red;
        margin-bottom: 1rem;
      }
    }

    input {
      font-family: "TheSansBReg";

      &[type="text"], &[type="email"], &[type="tel"] {
        width: 100%;
        background: $white;
        border: none;
        height: 50px;
        line-height: 50px;
        padding: 0 10px;
        margin: 0 0 15px;
        @include border-radius(0 15px 0 15px);
      }

      &[type="submit"] {
        background: $red;
        @include border-radius(0 15px 0 15px);
        line-height: 50px;
        padding: 0 30px;
        color: $white;
        border: none;
        @include transition(0.5s all ease-in-out);

        &:hover {
          background: $darkGrey;
        }
      }
    }

    textarea {
      @extend input, [type="text"];
      height: 80px;
    }
  }
}

@media (max-width: 768px) {
	// Hubspot form
	#requestInfoForm {
		form {
			.field {
				flex-basis: auto
			}
		}
	}
}
