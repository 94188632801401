// @font-face FSRufus*
@font-face {
  font-family: "FSRufusLight";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/fsrufus/FSRufus-Light.woff") format("woff");
}

@font-face {
  font-family: "FSRufusReg";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/fsrufus/FSRufus.woff") format("woff");
}

@font-face {
  font-family: "FSRufusBold";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/fsrufus/FSRufus-Bold.woff") format("woff");
}

// @font-face TheSansB*
@font-face {
  font-family: "TheSansBSemiLight";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/thesansb/TheSansB-W4SemiLight.woff") format("woff");
}

@font-face {
  font-family: "TheSansBReg";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/thesansb/TheSansB-W5Plain.woff") format("woff");
}

@font-face {
  font-family: "TheSansBSemiBold";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/thesansb/TheSansB-W6SemiBold.woff") format("woff");
}
