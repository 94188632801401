.Icon {
  .home & {
    width: 180px;
    height: auto;
    margin-bottom: 20px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (min-height: 600px) {
  .Icon {
    .home & {
      width: 250px;
    }
  }
}
