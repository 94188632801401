.homebg {
  width: 100%;
  height: 100%;
  @include flexbox();
  @include align-items(center);
}

@media (min-width: 768px) {

  .homebg {
    background-image: url('../../images/start-bg.jpg');
    background-size: cover;
  }
}
