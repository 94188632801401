.pageLinks {
  z-index: 997;

  .breadcrumb {
    display: block;
    background: $white;
    @include border-radius(55px);
    padding: 8.5px 15px;
    font-size: 0.8rem;
    text-align: center;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

  .pageLinks {
    position: absolute;
    top: 22px;
    right: 22px;

    .breadcrumb, .config-notice {
      display: inline-block;
      margin-right: 10px;
      text-align: left;
    }

    .config-notice {
      margin-bottom: 0;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
