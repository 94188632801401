// Products.js
.products {

  main {

    #productResults {
	    scroll-behavior: smooth;

      .product {
        margin: 15px 0;
        position: relative;

        .productStaticImg {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
          @include border-radius(0 15px 0 0);

          >div {
            @include border-radius(0 15px 0 0);
          }
        }

        .description {
          margin: 8px 0 0;
          background: $red;
          color: $white;
          padding: 30px 20px 60px;
          @include border-radius(0 0 0 15px);
          @include transition(0.5s all ease-in-out);
          position: relative;

          &:before {
            content: url('../../images/title-element.svg');
            position: absolute;
            display: block;
            top: -1px;
            left: 50%;
            @include transform(translateX(-50%));
            width: 50px;
            height: auto;
          }

          p {

            &.select {
              font-size: 14px;
              position: absolute;
              left: 20px;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .products {

    main {

      #productResults {
        margin: -15px 0 -30px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include flex-direction(column);
        height: 435px;
        overflow-x: scroll;
        overflow-y: hidden;
        place-content: flex-start;

        &::-webkit-scrollbar {
          width: 0 !important;
        }

        .product {
          margin: 15px;
          min-width: 250px;
          max-width: 250px;
          @include flex-basis(25%);
        }
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
