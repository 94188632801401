$white: #fff;
$red: #FF0200;
$lightGreyBg: #f3f3f3;
$darkGrey: #55555A;
$lightPurple: #A8438D;
$purple: #804080;
$orange: #E95E47;
$yellow: #E09F25;
$mint: #7FB8AC;
$green: #34A77A;
$turquoise: #00AEC7;
$blue: #0062A9;

.bgWhite {
  background: $white !important;

  &:hover {
    background: darken($white, 10%);
  }
}

.bgRed {
  background: $red !important;

  &:hover {
    background: darken($red, 10%);
  }
}

.bgLightGrey {
  background: $lightGreyBg !important;

  &:hover {
    background: darken($lightGreyBg, 10%);
  }
}

.bgDarkGrey {
  background: $darkGrey !important;

  &:hover {
    background: darken($darkGrey, 10%);
  }
}

.bgLightPurple {
  background: $lightPurple !important;

  &:hover {
    background: darken($lightPurple, 10%);
  }
}

.bgPurple {
  background: $purple !important;

  &:hover {
    background: darken($purple, 10%);
  }
}

.bgOrange {
  background: $orange !important;

  &:hover {
    background: darken($orange, 10%);
  }
}

.bgYellow {
  background: $yellow !important;

  &:hover {
    background: darken($yellow, 10%);
  }
}

.bgMint {
  background: $mint !important;

  &:hover {
    background: darken($mint, 10%);
  }
}

.bgGreen {
  background: $green !important;

  &:hover {
    background: darken($green, 10%);
  }
}

.bgTurquoise {
  background: $turquoise !important;

  &:hover {
    background: darken($turquoise, 10%);
  }
}

.bgBlue {
  background: $blue !important;

  &:hover {
    background: darken($blue, 10%);
  }
}
