body {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-family: "TheSansBReg";
}

h1, h2, h3, h4, p {
  color: $darkGrey;
}

h1, h2, h3, h4 {
  text-align: center;
}

h1, .h1 {
  font-family: "FSRufusReg";
}

h2, .h2 {
  font-family: "FSRufusReg";
  font-size: 24px;

  strong {
    font-family: "FSRufusBold";
  }

  .home & {
    margin-bottom: 0;
  }

  .modal & {
    color: $red;
  }
}

h3, .h3 {
  font-family: "FSRufusReg";
  font-size: 20px;
  margin-bottom: 2rem;
}

h4, .h4 {
  font-family: "TheSansBSemiBold";
  font-size: 16px;
  text-transform: uppercase;

  .application &, .product & {
    color: $white;
  }

  .accessories & {
    margin-top: 15px;
  }
}

p, li, label {
  font-family: "TheSansBReg";
  font-size: 14px;

  a {
    color: $red;

    &:hover {
      color: $darkGrey;
      text-decoration: underline;
    }
  }

  &.select {
    font-size: 16px;
    font-family: "TheSansBSemiBold";
    text-transform: uppercase;

    svg {
      margin-right: 10px;
    }
  }

  .product .description & {
    font-size: 12px;
    color: $white;
  }

  .modal & {
    font-size: 18px;
    color: $darkGrey;
    padding-right: 2rem;
  }

  &.opt-in {
    background: $white;
    padding: 10px 15px;
    @include border-radius(10px);

    input {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

a {

  &:hover {
    text-decoration: none;
  }
}

.breadcrumb, .config-notice {
  font-family: "TheSansBReg";
  font-size: 16px;
  text-transform: uppercase;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

  h1, h2, h3, h4 {
    text-align: left;

    .home & {
      text-align: center;
    }
  }

  h2, .h2 {
    font-size: 30px;
  }

  h3, .h3 {
    font-size: 24px;

    .product main & {
      margin-bottom: -2rem;
      position: relative;
    }
  }

  h4, .h4 {
    font-size: 16px;

    .accessories & {
      display: none;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
