.boxLinks {
  padding: 0;
  margin: -15px 0;

  .boxLink {
    list-style: none;
    margin: 15px 0;

    &.unavailable {

      .wrapper {
        background: #aaa !important;

        a {

          p {

            &.select {
              color: $red;
            }
          }
        }
      }
    }

    .wrapper {
      background: $red;
      padding: 30px 18px 60px;
      @include border-radius(0 0 0 15px);
      position: relative;
      height: 100%;

      &:before {
        content: url('../../images/title-element.svg');
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        @include transform(translateX(-50%));
        width: 50px;
        hwight: auto;
      }

      a {
        display: block;

        h4, p {
          color: $white;

          &.select {
            position: absolute;
            left: 20px;
            bottom: 0;
          }
        }
      }

      h4, p {
        color: $white;
      }
    }
  }
}
