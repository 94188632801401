.RequestInfoBtn {
  width: 200px;
  height: 60px;
  position: absolute;
  bottom: 0px;
  right: 260px;
  z-index: 998;

  .cls-1 {
    fill: $red;
  }
}
