// image sequences
.imageSequence {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    object-fit: cover;
    object-position: center;
    display: none;

    &.staticImage {
      opacity: 1;
      display: block;
      position: relative;
    }
  }
}

// config notice
.config-notice {
  display: block;
  background: $white;
  @include border-radius(55px);
  padding: 8.5px 15px;
  font-size: 0.8rem;
  text-align: center;
  color: $red;
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 99999;
  @include transform(translateX(-50%));
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

  .imageSequence {
    width: 660px;
    height: 330px;

    img {
      display: block;

      &.staticImage {
        position: absolute;
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .imageSequence {
    width: 900px;
    height: 450px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  .imageSequence {
    width: 1100px;
    height: 500px;
  }
}

/* XL Desktop screens 1440px and up */
@media (min-width: 1440px) {

  .imageSequence {
    width: 1200px;
    height: 600px;
  }
}
