// core button
.btn {
  background: $red;
  color: $white;
  text-transform: uppercase;
  font-family: "TheSansBReg";
  position: relative;
  padding: 7px 10px 7px 20px;
  @include border-radius(0);
  @include transition(0.5s all ease-in-out);
  white-space: nowrap;
  font-size: 0.8rem;
  display: inline-block;
  outline: none;
  @include box-shadow(none);

  &:focus, &:active {
    outline: none;
    box-shadow: none;
  }

  &:before {
    content: "+";
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    border: 5px solid $red;
    background: $lightGreyBg;
    font-size: 20px;
    color: $red;
    position: absolute;
    left: -30px;
    top: -3px;
    @include transition(0.5s all ease-in-out);
  }

  &:after {
    content: '';
    -webkit-mask: url('../../images/roundedArrow.svg') no-repeat 100% 100%;
    mask: url('../../images/roundedArrow.svg') no-repeat 100% 100%;
    -webkit-mask-size: cover;
    mask-size: cover;
    width: auto;
    height: 35px;
    width: 18px;
    position: absolute;
    display: inline-block;
    right: -18px;
    top: -1px;
    @include transition(0.5s all ease-in-out);
    background-color: $red;
  }

  &:hover {
    background: $darkGrey;
    color: $white;

    &:before {
      border-color: $darkGrey;
      color: $darkGrey;
      @include transform(rotate(360deg));
    }

    &:after {
      background-color: $darkGrey;
    }
  }

  /* Home buttons */
  .home & {
    margin-top: 20px;
  }

  .accessories & {
    margin: 0 10px 10px 0;
    padding: 7px 10px;
    display: inline-block;

    &:before, &:after {
      display: none;
    }
  }

  // request more info btn
  &.request-more-info {
    position: absolute;
    bottom: 80px;
    right: 52px;
    z-index: 2;

    .react-responsive-modal-modal & {
      position: relative;
      bottom: auto;
      right: auto;
      margin-left: 35px;
    }
  }

  // info buttons
  &.infoBtn {
    background: $darkGrey;

    &:before, &:after {
      border-color: $darkGrey;
      color: $darkGrey;
    }

    &:before {
      content: 'i';
      text-transform: lowercase;
      font-size: 16px;
      font-weight: 600;
      padding-top: 4px;
    }

    &:after {
      background-color: $darkGrey;
    }

    &:hover {
      background: $red;

      &:before, &:after {
        border-color: $red;
        color: $red;
      }

      &:after {
        background-color: $red;
      }
    }
  }
}

// .btn-reverse
.btn-reverse {
  @extend .btn;
  padding: 7px 20px 7px 10px;

  &:before {
    left: auto;
    right: -30px;
  }

  &:after {
    right: auto;
    left: -18px;
    @include transform(rotate(180deg));
  }
}

// grey button with dots
.dotsBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  display: inline-block;
  width: 36px;
  height: 36px;
  background: $darkGrey;
  @include border-radius(50%);
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);
  vertical-align: middle;
  @include transition(0.5s all ease-in-out);

  svg {
    font-size: 18px;
    color: $white;
  }

  &:hover {
    background: $red;
  }
}

// back button
.backBtn {
  position: relative;
  z-index: 10;
  display: inline-block;
  background: $red;
  width: 36px;
  height: 36px;
  color: white;
  @include border-radius(50%);
  position: absolute;
  bottom: 15px;
  left: 15px;
  cursor: pointer;
  @include transition(0.5s all ease-in-out);
  text-align: center;
  font-size: 20px;
  padding-top: 4px;

  &:hover {
    background: $darkGrey;
    color: $white;
  }

  &:after {
    content: 'Back';
    font-size: 16px;
    text-transform: uppercase;
    color: $darkGrey;
    position: absolute;
    left: calc(100% + 10px);
    top: 50%;
    @include transform(translateY(-50%));
  }
}

// feature buttons
.featureBtns {
  position: relative;
  text-align: center;
  margin: 15px 0;
  display: none;

  li {
    margin-bottom: 15px;
    display: inline-block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.showfeatureBtns{
	.featureBtns{
		display: block;
	}
}

// accessories buttons
.accessoryBtns {

  li {
    display: inline-block;;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

  .btn {

    &.viewProduct {
      position: absolute;
      left: 75px;
      bottom: 0;
    }

    &.viewAllProducts {
      position: absolute;
      bottom: 22px;
      left: 56px;
    }
    /* / */

    .accessories & {
      margin: 0;

      &:before, &:after {
        display: inline-block;
      }
    }
  }

  .dotsBtn {
    position: relative;
    top: auto;
    right: auto;

    &:after {

    }
  }

  .featureBtns {
    position: absolute;
    right: 75px;
    bottom: 0;
    text-align: right;
    margin: 0;

    li {
      display: block;
    }
  }

  .backBtn {
    left: 22px;
    bottom: 22px;
    font-size: 24px;
    padding-top: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .btn {
    font-size: 1rem;

    &:before {
      width: 50px;
      height: 50px;
      font-size: 28px;
      left: -35px;
      top: -6px;
    }

    &:after {
      height: 40px;
      width: 21px;
      right: -22px;
      top: -1px;
    }

    &.infoBtn {

      &:before {
        font-size: 22px;
      }
    }

    &.viewProduct {
      position: absolute;
      left: 75px;
      bottom: 0;
    }

    &-reverse {
      padding: 7px 20px 7px 10px;

      &:before {
        left: auto;
        right: -35px;
      }

      &:after {
        right: auto;
        left: -20px;
        @include transform(rotate(180deg));
      }
    }

    .accessories & {
      padding: 7px 10px 7px 20px;

      &-reverse {
        padding: 7px 20px 7px 10px;
      }
    }
  }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
