// fade enter
.fade-enter {
  opacity: 0;

  &-active {
    opacity: 1;
    transition: opacity 1s;
  }
}

// fade exit
.fade-exit {
  opacity: 1;

  &-active {
    opacity: 0;
    transition: opacity 1s;
  }
}
