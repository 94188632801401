// interactive product
.productInteractive {

  .btn, .btn-reverse {

    a {
      color: $white;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  // main interactive
  .features {
    visibility: visible;
    @include transition(opacity 0.5s);
    transition-delay: 2s;
    opacity: 1;
    display: none;

    &.hide {
      visibility: hidden;
      @include transition(opacity 0.5s);
      opacity: 0;
    }
  }

  main {

    .userInterfaceBtn, .exploreTechnologiesBtn {
      position: absolute;
    }
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px){

  // interactive product
  .productInteractive {

    // main interactive
    .features {
      display: block;
    }

    main {

      // Chorus 1
      &.chorus-1 {

        .userInterfaceBtn {
          left: 18%;
          top: 26%;
        }

        .exploreTechnologiesBtn {
          left: 53%;
          top: 52%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.ultra-filter {
              top: 52%;
              left: 25%;
            }

            &.purification-pack {
              top: 58%;
              left: 59%;
            }

            &.point-of-use-filter {
              top: 17%;
              left: 19%;
            }

            &.uv-light {
              top: 43%;
              left: 62%;
            }

            &.ultra-micron-filter {
              top: 70%;
              left: 17%;
            }

            &.puresure-system {
              top: 72%;
              left: 58%;
            }
          }
        }
      }

      // Chorus 1 Complete
      &.chorus-1-complete {

        .userInterfaceBtn {
          left: 51%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 24%;
          top: 61%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.uv-light {
              top: 49%;
              left: 60%;
            }

            &.purification-pack {
              top: 65%;
              left: 58%;
            }

            &.point-of-use-filter {
              top: 11%;
              left: 12%;
            }

            &.pre-treatment {
              top: 62%;
              left: 25%;
            }

            &.reverse-osmosis {
              top: 48%;
              left: 21%;
            }

            &.composite-vent-filter {
              top: 70%;
              left: 64%;
            }
          }
        }
      }

      // Chorus 2
      &.chorus-2 {

        .userInterfaceBtn {
          left: 50%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 23%;
          top: 62%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 59%;
              left: 58%;
            }

            &.pre-treatment {
              top: 54%;
              left: 23%;
            }

            &.reverse-osmosis {
              top: 37%;
              left: 19%;
            }

            &.composite-vent-filter {
              top: 72%;
              left: 57%;
            }
          }
        }
      }

      // Chorus 2+
      &.chorus-2-plus {

        .userInterfaceBtn {
          left: 51%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 24%;
          top: 59%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 62%;
              left: 58%;
            }

            &.point-of-use-filter {
              top: 13%;
              left: 12%;
            }

            &.uv-light {
              top: 49%;
              left: 60%;
            }

            &.pre-treatment {
              top: 60%;
              left: 25%;
            }

            &.reverse-osmosis {
              top: 46%;
              left: 21%;
            }

            &.composite-vent-filter {
              top: 12%;
              left: 60%;
            }

            &.edi-stack {
              top: 74%;
              left: 30%;
            }

            &.optimiser-pack {
              top: 34%;
              left: 60%;
            }
          }
        }
      }

      // Chorus 3
      &.chorus-3 {

        .userInterfaceBtn {
          left: 51%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 23%;
          top: 60%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.pre-treatment {
              top: 64%;
              left: 58%;
            }

            &.reverse-osmosis {
              top: 45%;
              left: 19%;
            }

            &.composite-vent-filter {
              top: 31%;
              left: 63%;
            }
          }
        }
      }
    }
  }
}

/* 992px and up */
@media (min-width: 992px){

  // interactive product
  .productInteractive {

    main {

      // Chorus 1
      &.chorus-1 {

        .userInterfaceBtn {
          left: 23%;
          top: 27%;
        }

        .exploreTechnologiesBtn {
          left: 53%;
          top: 52%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.ultra-filter {
              top: 52%;
              left: 27%;
            }

            &.purification-pack {
              top: 58%;
              left: 58%;
            }

            &.point-of-use-filter {
              top: 18%;
              left: 21%;
            }

            &.uv-light {
              top: 43%;
              left: 61%;
            }

            &.ultra-micron-filter {
              top: 70%;
              left: 19%;
            }

            &.puresure-system {
              top: 72%;
              left: 57%;
            }
          }
        }
      }

      // Chorus 1 Complete
      &.chorus-1-complete {

        .userInterfaceBtn {
          left: 51%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 26%;
          top: 59%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.uv-light {
              top: 49%;
              left: 59%;
            }

            &.purification-pack {
              top: 65%;
              left: 57%;
            }

            &.point-of-use-filter {
              top: 11%;
              left: 14%;
            }

            &.pre-treatment {
              top: 62%;
              left: 26%;
            }

            &.reverse-osmosis {
              top: 48%;
              left: 22%;
            }

            &.composite-vent-filter {
              top: 70%;
              left: 64%;
            }
          }
        }
      }

      // Chorus 2
      &.chorus-2 {

        .userInterfaceBtn {
          left: 50%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 25%;
          top: 57%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 59%;
              left: 58%;
            }

            &.pre-treatment {
              top: 54%;
              left: 24%;
            }

            &.reverse-osmosis {
              top: 37%;
              left: 20%;
            }

            &.composite-vent-filter {
              top: 72%;
              left: 57%;
            }
          }
        }
      }

      // Chorus 2+
      &.chorus-2-plus {

        .userInterfaceBtn {
          left: 51%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 26%;
          top: 59%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 62%;
              left: 57%;
            }

            &.point-of-use-filter {
              top: 13%;
              left: 14%;
            }

            &.uv-light {
              top: 49%;
              left: 59%;
            }

            &.pre-treatment {
              top: 60%;
              left: 26%;
            }

            &.reverse-osmosis {
              top: 46%;
              left: 22%;
            }

            &.composite-vent-filter {
              top: 13%;
              left: 60%;
            }

            &.edi-stack {
              top: 74%;
              left: 30%;
            }

            &.optimiser-pack {
              top: 34%;
              left: 60%;
            }
          }
        }
      }

      // Chorus 3
      &.chorus-3 {

        .userInterfaceBtn {
          left: 51%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 26%;
          top: 57%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.pre-treatment {
              top: 64%;
              left: 58%;
            }

            &.reverse-osmosis {
              top: 45%;
              left: 20%;
            }

            &.composite-vent-filter {
              top: 31%;
              left: 62%;
            }
          }
        }
      }
    }
  }
}

/* 1200px and up */
@media (min-width: 1200px){

  // interactive product
  .productInteractive {

    main {

      // Chorus 1
      &.chorus-1 {

        .userInterfaceBtn {
          left: 26%;
          top: 26%;
        }

        .exploreTechnologiesBtn {
          left: 53%;
          top: 52%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.ultra-filter {
              top: 52%;
              left: 29%;
            }

            &.purification-pack {
              top: 58%;
              left: 58%;
            }

            &.point-of-use-filter {
              top: 17%;
              left: 25%;
            }

            &.uv-light {
              top: 43%;
              left: 61%;
            }

            &.ultra-micron-filter {
              top: 70%;
              left: 22%;
            }

            &.puresure-system {
              top: 72%;
              left: 57%;
            }
          }
        }
      }

      // Chorus 1 Complete
      &.chorus-1-complete {

        .userInterfaceBtn {
          left: 50%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 30%;
          top: 59%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.uv-light {
              top: 49%;
              left: 59%;
            }

            &.purification-pack {
              top: 65%;
              left: 57%;
            }

            &.point-of-use-filter {
              top: 11%;
              left: 18%;
            }

            &.pre-treatment {
              top: 62%;
              left: 30%;
            }

            &.reverse-osmosis {
              top: 48%;
              left: 26%;
            }

            &.composite-vent-filter {
              top: 70%;
              left: 64%;
            }
          }
        }
      }

      // Chorus 2
      &.chorus-2 {

        .userInterfaceBtn {
          left: 50%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 29%;
          top: 57%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 59%;
              left: 58%;
            }

            &.pre-treatment {
              top: 54%;
              left: 28%;
            }

            &.reverse-osmosis {
              top: 37%;
              left: 24%;
            }

            &.composite-vent-filter {
              top: 72%;
              left: 57%;
            }
          }
        }
      }

      // Chorus 2+
      &.chorus-2-plus {

        .userInterfaceBtn {
          left: 50%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 30%;
          top: 59%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 62%;
              left: 57%;
            }

            &.point-of-use-filter {
              top: 11%;
              left: 18%;
            }

            &.uv-light {
              top: 49%;
              left: 59%;
            }

            &.pre-treatment {
              top: 60%;
              left: 30%;
            }

            &.reverse-osmosis {
              top: 46%;
              left: 26%;
            }

            &.composite-vent-filter {
              top: 10%;
              left: 59%;
            }

            &.edi-stack {
              top: 74%;
              left: 34%;
            }

            &.optimiser-pack {
              top: 34%;
              left: 60%;
            }
          }
        }
      }

      // Chorus 3
      &.chorus-3 {

        .userInterfaceBtn {
          left: 51%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 29%;
          top: 57%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.pre-treatment {
              top: 64%;
              left: 59%;
            }

            &.reverse-osmosis {
              top: 45%;
              left: 24%;
            }

            &.composite-vent-filter {
              top: 31%;
              left: 61%;
            }
          }
        }
      }
    }
  }
}

/* 1440px and up */
@media (min-width: 1440px){

  // interactive product
  .productInteractive {

    main {

      // Chorus 1
      &.chorus-1 {

        .userInterfaceBtn {
          left: 27%;
          top: 28%;
        }

        .exploreTechnologiesBtn {
          left: 53%;
          top: 52%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.ultra-filter {
              top: 52%;
              left: 30%;
            }

            &.purification-pack {
              top: 58%;
              left: 58%;
            }

            &.point-of-use-filter {
              top: 19%;
              left: 26%;
            }

            &.uv-light {
              top: 43%;
              left: 61%;
            }

            &.ultra-micron-filter {
              top: 70%;
              left: 23%;
            }

            &.puresure-system {
              top: 72%;
              left: 57%;
            }
          }
        }
      }

      // Chorus 1 Complete
      &.chorus-1-complete {

        .userInterfaceBtn {
          left: 50%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 30%;
          top: 59%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.uv-light {
              top: 49%;
              left: 59%;
            }

            &.purification-pack {
              top: 65%;
              left: 57%;
            }

            &.point-of-use-filter {
              top: 13%;
              left: 19%;
            }

            &.pre-treatment {
              top: 62%;
              left: 31%;
            }

            &.reverse-osmosis {
              top: 48%;
              left: 27%;
            }

            &.composite-vent-filter {
              top: 70%;
              left: 64%;
            }
          }
        }
      }

      // Chorus 2
      &.chorus-2 {

        .userInterfaceBtn {
          left: 50%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 29%;
          top: 57%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 59%;
              left: 58%;
            }

            &.pre-treatment {
              top: 54%;
              left: 29%;
            }

            &.reverse-osmosis {
              top: 37%;
              left: 25%;
            }

            &.composite-vent-filter {
              top: 72%;
              left: 57%;
            }
          }
        }
      }

      // Chorus 2+
      &.chorus-2-plus {

        .userInterfaceBtn {
          left: 50%;
          top: 31%;
        }

        .exploreTechnologiesBtn {
          left: 30%;
          top: 59%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.purification-pack {
              top: 62%;
              left: 57%;
            }

            &.point-of-use-filter {
              top: 14%;
              left: 19%;
            }

            &.uv-light {
              top: 49%;
              left: 59%;
            }

            &.pre-treatment {
              top: 60%;
              left: 31%;
            }

            &.reverse-osmosis {
              top: 46%;
              left: 27%;
            }

            &.composite-vent-filter {
              top: 14%;
              left: 59%;
            }

            &.edi-stack {
              top: 74%;
              left: 34%;
            }

            &.optimiser-pack {
              top: 36%;
              left: 59%;
            }
          }
        }
      }

      // Chorus 3
      &.chorus-3 {

        .userInterfaceBtn {
          left: 51%;
          top: 49%;
        }

        .exploreTechnologiesBtn {
          left: 29%;
          top: 57%;
        }

        .accessoryBtns {

          li {
            position: absolute;

            &.pre-treatment {
              top: 64%;
              left: 57%;
            }

            &.reverse-osmosis {
              top: 45%;
              left: 26%;
            }

            &.composite-vent-filter {
              top: 31%;
              left: 61%;
            }
          }
        }
      }
    }
  }
}
