// veolia logo
.Logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  @include transform(translateX(-50%));
  width: 150px;
  height: auto;
  z-index: 999;
}

// water technologies logo
.WaterTechLogo {
  position: absolute;
  top: -21px;
  left: 50%;
  @include transform(translateX(-50%));
  width: 220px;
  height: auto;
  z-index: 999;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .Logo {
    @include transform(translateX(0));
    left: auto;
    right: 100px;
    width: 200px;
  }

  .WaterTechLogo {
    @include transform(translateX(0));
    left: 30px;
    width: 270px;
    top: -26px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
