.uiFeatures {
  visibility: visible;
  @include transition(opacity 0.5s);
  transition-delay: 2s;
  opacity: 1;

  &.hide {
    visibility: hidden;
    @include transition(opacity 0.5s);
    opacity: 0;
  }

  li {

    &.feature {
      background: $turquoise;
      color: $white;
      text-transform: uppercase;
      font-family: "TheSansBReg";
      position: relative;
      padding: 7px 15px;
      white-space: nowrap;
      font-size: 1rem;
      display: inline-block;
      position: absolute;

      &:after {
        content: '';
        -webkit-mask: url('../../images/roundedArrow.svg') no-repeat 100% 100%;
        mask: url('../../images/roundedArrow.svg') no-repeat 100% 100%;
        -webkit-mask-size: cover;
        mask-size: cover;
        width: auto;
        height: 38px;
        width: 21px;
        position: absolute;
        display: inline-block;
        right: -21px;
        top: 0;
        background-color: $turquoise;
      }

      &.reverse {

        &:after {
          @include transform(rotate(180deg));
          right: auto;
          left: -20px;
        }
      }

      &.display-of-toc {

        .chorus-1 & {
          top: 46%;
          left: 26%;
        }

      }

      &.infobar {

        .chorus-1 & {
          top: 53%;
          left: 47%;
        }

        .chorus-1-complete & {
          top: 38%;
          left: 52%;
        }

        .chorus-2 & {
          top: 53%;
          left: 52%;
        }

        .chorus-2-plus & {
          top: 38%;
          left: 52%;
        }

        .chorus-3 & {
          top: 54%;
          left: 52%;
        }
      }

      &.quality-display {

        .chorus-1-complete & {
          top: 33%;
          left: 31%;
        }

        .chorus-2 & {
          top: 50%;
          left: 32%;
        }

        .chorus-2-plus & {
          top: 33%;
          left: 31%;
        }

        .chorus-3 & {
          top: 50%;
          left: 30%;
        }
      }

      &.reservoir-level {

        .chorus-1-complete & {
          top: 46%;
          left: 52%;
        }

        .chorus-2 & {
          top: 63%;
          left: 52%;
        }

        .chorus-2-plus & {
          top: 47%;
          left: 52%;
        }

        .chorus-3 & {
          top: 62%;
          left: 52%;
        }
      }

      &.accept-button {

        .chorus-1-complete & {
          top: 63%;
          left: 32%;
        }

        .chorus-2 & {
          top: 79%;
          left: 33%;
        }

        .chorus-2-plus & {
          top: 62%;
          left: 33%;
        }
      }
    }
  }
}

/* 768px and up */
@media (min-width: 768px) {

  .uiFeatures {

    li {

      &.feature {

        &.display-of-toc {

          .chorus-1 & {
            top: 45%;
            left: 13%;
          }

        }

        &.infobar {

          .chorus-1 & {
            top: 50%;
            left: 49%;
          }

          .chorus-1-complete & {
            top: 34%;
            left: 54%;
          }

          .chorus-2 & {
            top: 49%;
            left: 54%;
          }

          .chorus-2-plus & {
            top: 33%;
            left: 54%;
          }

          .chorus-3 & {
            top: 49%;
            left: 54%;
          }
        }

        &.quality-display {

          .chorus-1-complete & {
            top: 30%;
            left: 17%;
          }

          .chorus-2 & {
            top: 47%;
            left: 19%;
          }

          .chorus-2-plus & {
            top: 31%;
            left: 17%;
          }

          .chorus-3 & {
            top: 47%;
            left: 17%;
          }
        }

        &.reservoir-level {

          .chorus-1-complete & {
            top: 47%;
            left: 54%;
          }

          .chorus-2 & {
            top: 61%;
            left: 54%;
          }

          .chorus-2-plus & {
            top: 45%;
            left: 54%;
          }

          .chorus-3 & {
            top: 61%;
            left: 54%;
          }
        }

        &.accept-button {

          .chorus-1-complete & {
            top: 60%;
            left: 21%;
          }

          .chorus-2 & {
            top: 76%;
            left: 21%;
          }

          .chorus-2-plus & {
            top: 60%;
            left: 21%;
          }
        }
      }
    }
  }
}

/* 992px and up */
@media (min-width: 992px) {

  .uiFeatures {

    li {

      &.feature {

        &.display-of-toc {

          .chorus-1 & {
            top: 45%;
            left: 19%;
          }

        }

        &.infobar {

          .chorus-1 & {
            top: 51%;
            left: 48%;
          }

          .chorus-1-complete & {
            top: 36%;
            left: 53%;
          }

          .chorus-2 & {
            top: 52%;
            left: 53%;
          }

          .chorus-2-plus & {
            top: 37%;
            left: 53%;
          }

          .chorus-3 & {
            top: 52%;
            left: 53%;
          }
        }

        &.quality-display {

          .chorus-1-complete & {
            top: 32%;
            left: 24%;
          }

          .chorus-2 & {
            top: 50%;
            left: 25%;
          }

          .chorus-2-plus & {
            top: 32%;
            left: 24%;
          }

          .chorus-3 & {
            top: 49%;
            left: 23%;
          }
        }

        &.reservoir-level {

          .chorus-1-complete & {
            top: 46%;
            left: 53%;
          }

          .chorus-2 & {
            top: 61%;
            left: 53%;
          }

          .chorus-2-plus & {
            top: 46%;
            left: 53%;
          }

          .chorus-3 & {
            top: 61%;
            left: 53%;
          }
        }

        &.accept-button {

          .chorus-1-complete & {
            top: 61%;
            left: 27%;
          }

          .chorus-2 & {
            top: 76%;
            left: 28%;
          }

          .chorus-2-plus & {
            top: 62%;
            left: 28%;
          }
        }
      }
    }
  }
}

/* 1200px and up */
@media (min-width: 1200px) {

  .uiFeatures {

    li {

      &.feature {

        &.display-of-toc {

          .chorus-1 & {
            top: 46%;
            left: 23%;
          }

        }

        &.infobar {

          .chorus-1 & {
            top: 52%;
            left: 48%;
          }

          .chorus-1-complete & {
            top: 36%;
            left: 52%;
          }

          .chorus-2 & {
            top: 53%;
            left: 52%;
          }

          .chorus-2-plus & {
            top: 36%;
            left: 52%;
          }

          .chorus-3 & {
            top: 54%;
            left: 52%;
          }
        }

        &.quality-display {

          .chorus-1-complete & {
            top: 31%;
            left: 28%;
          }

          .chorus-2 & {
            top: 49%;
            left: 29%;
          }

          .chorus-2-plus & {
            top: 31%;
            left: 28%;
          }

          .chorus-3 & {
            top: 49%;
            left: 27%;
          }
        }

        &.reservoir-level {

          .chorus-1-complete & {
            top: 46%;
            left: 52%;
          }

          .chorus-2 & {
            top: 63%;
            left: 52%;
          }

          .chorus-2-plus & {
            top: 46%;
            left: 52%;
          }

          .chorus-3 & {
            top: 63%;
            left: 52%;
          }
        }

        &.accept-button {

          .chorus-1-complete & {
            top: 63%;
            left: 31%;
          }

          .chorus-2 & {
            top: 81%;
            left: 31%;
          }

          .chorus-2-plus & {
            top: 62%;
            left: 31%;
          }
        }
      }
    }
  }
}

/* 1440px and up */
@media (min-width: 1440px) {

  .uiFeatures {

    li {

      &.feature {

        &.display-of-toc {

          .chorus-1 & {
            top: 46%;
            left: 23%;
          }

        }

        &.infobar {

          .chorus-1 & {
            top: 52%;
            left: 48%;
          }

          .chorus-1-complete & {
            top: 38%;
            left: 52%;
          }

          .chorus-2 & {
            top: 53%;
            left: 52%;
          }

          .chorus-2-plus & {
            top: 38%;
            left: 52%;
          }

          .chorus-3 & {
            top: 54%;
            left: 52%;
          }
        }

        &.quality-display {

          .chorus-1-complete & {
            top: 33%;
            left: 29%;
          }

          .chorus-2 & {
            top: 49%;
            left: 30%;
          }

          .chorus-2-plus & {
            top: 33%;
            left: 29%;
          }

          .chorus-3 & {
            top: 49%;
            left: 29%;
          }
        }

        &.reservoir-level {

          .chorus-1-complete & {
            top: 47%;
            left: 52%;
          }

          .chorus-2 & {
            top: 62%;
            left: 52%;
          }

          .chorus-2-plus & {
            top: 47%;
            left: 52%;
          }

          .chorus-3 & {
            top: 63%;
            left: 52%;
          }
        }

        &.accept-button {

          .chorus-1-complete & {
            top: 63%;
            left: 31%;
          }

          .chorus-2 & {
            top: 79%;
            left: 31%;
          }

          .chorus-2-plus & {
            top: 62%;
            left: 32%;
          }
        }
      }
    }
  }
}
