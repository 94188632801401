/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

  .accessories, .config-notice {
    visibility: visible;
    @include transition(opacity 0.5s);
    transition-delay: 2s;
    opacity: 1;

    &.hide {
      visibility: hidden;
      @include transition(opacity 0.5s);
      opacity: 0;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
