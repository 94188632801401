.modal {
  max-width: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(243,243,243,0.95);
  @include flexbox();
  @include flex-direction(column);
  @include align-items(left);
  @include justify-content(center);
  z-index: 998;

  ul {
    list-style: square;
    padding-left: 2rem;
  }
}

.react-responsive-modal-overlay {

  .react-responsive-modal-modal {
    @extend .modal;
    border: 15px solid #fff;

    .react-responsive-modal-closeButton {
      display: none;
    }
  }
}

@media (min-width: 768px) {

  .react-responsive-modal-overlay {

    .react-responsive-modal-modal {
      border: 23px solid #fff;
    }
  }
}
