.App {
  background: #f3f3f3;
  border: 15px solid #fff;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  padding: 60px 0 80px;
  @include flexbox();
  @include align-items(center);
  @include flex-flow(row);
  @include justify-content(center);
  @include flex-direction(column);

  .wrapper {
    width: 100%;

    aside {
      padding: 0 15px;

      .scrollArrows {
        margin-top: 30px;
        display: none;

        svg {
          width: 27px;
          height: 27px;
          margin-right: 10px;
          cursor: pointer;

          path {
            fill: $darkGrey;
            @include transition(0.5s all ease-in-out);
          }

          &:hover {

            path {
              fill: $red;
            }
          }
        }
      }
    }

    main {
      padding: 0 15px;
      position: relative;
    }

    &.home {

      main {
        padding-top: 40
      }
    }

    &.product {

      main {
        text-align: center;
      }
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
 .App {
   height: 100vh;
   border-width: 23px;
   overflow: hidden;
   padding: 0;

   .wrapper {

     &.request-info {
       max-height: 70%;
       overflow: hidden;

       main {
         overflow-y: scroll;
       }
     }

     aside {
       @include flexbox();
       @include flex-basis(25%);
       @include align-content(center);
       @include flex-flow(row wrap);
       padding: 0 40px;
       border-right: 1px solid #ddd;

       .scrollArrows {
         display: block;
       }
     }

     main {
       @include flex-basis(75%);
       @include align-items(center);
       padding: 0 40px;
     }

     &.applications, &.products {

       main {
         padding-right: 0;
       }
     }

     &.product {

       main {
         text-align: auto;
       }
     }

     &.productInteractive {
       @include flex-basis(100%);
       height: calc(100vh - 46px);

       main {
         padding: 0;
         height: 100%;
         @include flexbox();
         @include align-items(center);
         @include justify-content(center);
       }
     }
   }
 }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
